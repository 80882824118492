import React from "react";
import { Link } from 'react-router-dom';
import { FaMicrochip, FaCode, FaUsers, FaArrowRight } from 'react-icons/fa';
import PCBViewer from '../Components/PCBViewer';
import ProductShowCase from '../Components/ProductShowCase';
import UpcomingKickstarters from "../Components/UpcomingKickstarters";
import Header from '../Layouts/Header'; // Import the separate Header component
import Footer from '../Layouts/Footer'; // Assuming you have a separate Footer component as well

const ServiceCard = ({ icon: Icon, title, description }) => (
  <div className="bg-[#1E2C31] p-4 sm:p-6 rounded-lg shadow-lg hover:bg-[#243338] transition">
    <Icon className="text-3xl sm:text-4xl mb-3 sm:mb-4 text-[#FCEB55]" />
    <h3 className="text-lg sm:text-xl font-medium mb-2 text-[#FCEB55]">{title}</h3>
    <p className="text-sm sm:text-base text-gray-300 mb-3 sm:mb-4">{description}</p>
    <Link to="/services" className="text-[#FCEB55] hover:text-white transition flex items-center text-sm sm:text-base">
      Learn More <FaArrowRight className="ml-2" />
    </Link>
  </div>
);

const HomePage = () => {
  return (
    <div className="min-h-screen bg-[#293C43] text-white">
      <Header />
      
      <main className="container mx-auto px-4 py-8 sm:py-12 text-center">
        <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-2 sm:mb-3 text-[#FCEB55]">Devices That Evolves with Community</h1>
        <p className="text-lg sm:text-xl mb-8 sm:mb-12 text-gray-300">Innovating Hardware & Software Solutions</p>

        {/* New Services Button */}
        <div className="mb-8 sm:mb-12">
          <Link to="/services" className="inline-block px-6 py-3 bg-[#FCEB55] text-[#293C43] rounded hover:bg-[#E6D44E] transition font-semibold text-base sm:text-lg">
            Explore Our Services
          </Link>
        </div>
        
        <section className="mb-12 sm:mb-20">
          <h2 className="text-2xl sm:text-3xl font-semibold mb-4 sm:mb-6 text-white">Our Flagship Product</h2>
          <div className="bg-[#1E2C31] p-4 sm:p-8 rounded-lg shadow-lg">
            <div className="bg-gray-700 p-2 sm:p-4 mb-3 sm:mb-4 text-white">
              <PCBViewer />
              <ProductShowCase />
              <UpcomingKickstarters />
            </div>
            <h3 className="text-xl sm:text-2xl font-medium mt-4 sm:mt-6 mb-2 sm:mb-3 text-[#FCEB55]">Playard Retro PCB</h3>
            <p className="mb-4 sm:mb-6 text-gray-300 text-sm sm:text-base">A versatile device that evolves with our community</p>
            <div className="space-y-2 sm:space-y-0 sm:space-x-4">
              <Link to="/shop" className="inline-block w-full sm:w-auto px-4 sm:px-6 py-2 sm:py-3 bg-[#FCEB55] text-[#293C43] rounded hover:bg-[#E6D44E] transition font-semibold text-sm sm:text-base">
                Buy Now
              </Link>
              <Link to="/documentation" className="inline-block w-full sm:w-auto mt-2 sm:mt-0 px-4 sm:px-6 py-2 sm:py-3 border border-[#FCEB55] text-[#FCEB55] rounded hover:bg-[#FCEB55] hover:text-[#293C43] transition text-sm sm:text-base">
                Learn More
              </Link>
            </div>
          </div>
        </section>
        
        <section className="mb-12 sm:mb-20">
          <h2 className="text-2xl sm:text-3xl font-semibold mb-4 sm:mb-6 text-white">Our Services</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 lg:gap-8">
            <ServiceCard 
              icon={FaMicrochip}
              title="Hardware Development"
              description="Custom design and prototyping of retro-inspired gaming hardware and IoT devices."
            />
            <ServiceCard 
              icon={FaCode}
              title="Software Development"
              description="Creation of firmware, games, and applications tailored for our unique hardware platforms."
            />
            <ServiceCard 
              icon={FaUsers}
              title="Community-Driven Innovation"
              description="Collaborative product development incorporating user feedback and contributions."
            />
          </div>
          <div className="mt-6 sm:mt-8">
            <Link to="/services" className="inline-block px-4 sm:px-6 py-2 sm:py-3 bg-[#FCEB55] text-[#293C43] rounded hover:bg-[#E6D44E] transition font-semibold text-sm sm:text-base">
              View All Services
            </Link>
          </div>
        </section>
        
        <section>
          <h2 className="text-2xl sm:text-3xl font-semibold mb-4 sm:mb-6 text-white">Get Started with Playard</h2>
          <div className="space-y-2 sm:space-y-4">
            <a href="https://play-ard.github.io/playard-education/" className="block w-full px-4 sm:px-6 py-2 sm:py-3 bg-[#FCEB55] text-[#293C43] rounded hover:bg-[#E6D44E] transition font-semibold text-sm sm:text-base">
              Explore Our Educational Resources
            </a>
            <Link to="/about" className="block w-full px-4 sm:px-6 py-2 sm:py-3 bg-[#1E2C31] text-white rounded hover:bg-[#243338] transition text-sm sm:text-base">
              Learn About Our Mission
            </Link>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default HomePage;