import React, { useState, useRef, useEffect } from 'react';

import ThreeDViewer from './Alternative3DViewer';

const PixelArtLCD = ({ x, y, width, height, onMouseDown }) => (
  <rect
    x={x}
    y={y}
    width={width}
    height={height}
    fill="#000000"
    stroke="#cccccc"
    strokeWidth="0.5"
    onMouseDown={onMouseDown}
    style={{cursor: 'move'}}
  />
);

const PixelArtUSBC = ({ x, y, width, height, onMouseDown }) => (
  <g onMouseDown={onMouseDown} style={{cursor: 'move'}}>
    <rect x={x} y={y} width={width} height={height} fill="#333333" />
    <rect x={x + width/4} y={y + height/4} width={width/2} height={height/2} fill="#666666" rx={height/4} ry={height/4} />
  </g>
);

const PixelArtJoystick = ({ x, y, width, height, onMouseDown }) => (
  <g onMouseDown={onMouseDown} style={{cursor: 'move'}}>
    <rect x={x} y={y} width={width} height={height} fill="#cccccc" />
    <circle cx={x + width/2} cy={y + height/2} r={width/3} fill="#333333" />
    <circle cx={x + width/2} cy={y + height/2} r={width/6} fill="#666666" />
  </g>
);

const PCBViewer = () => {
  const [currentSide, setCurrentSide] = useState('front');
  const [showRealProduct, setShowRealProduct] = useState(false);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const containerRef = useRef(null);

  const modelPath = '/models/playard_retro.obj'; // Update this with your actual model path
  const materialPath = '/models/playard_retro.mtl';

  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        setContainerSize({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
        });
      }
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);


  const [components, setComponents] = useState({
    lcd: { x: -25, y: -15, width: 50, height: 30 },
    joystick: { x: 30, y: 20, width: 15, height: 15 },
    buttons: { x: -45, y: 20, width: 25, height: 10 },
    typeC: { x: -10, y: 35, width: 20, height: 8 },
    sdCard: { x: -20, y: 20, width: 15, height: 20 },
    header: { x: 5, y: 20, width: 30, height: 10 }
  });

  const svgRef = useRef(null);
  const [draggedComponent, setDraggedComponent] = useState(null);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

  const toggleSide = () => {
    setCurrentSide(currentSide === 'front' ? 'back' : 'front');
  };

  const startDrag = (e, componentName) => {
    const svgPoint = svgRef.current.createSVGPoint();
    svgPoint.x = e.clientX;
    svgPoint.y = e.clientY;
    const transformedPoint = svgPoint.matrixTransform(svgRef.current.getScreenCTM().inverse());
    
    setDraggedComponent(componentName);
    setDragOffset({
      x: transformedPoint.x - components[componentName].x,
      y: transformedPoint.y - components[componentName].y
    });
  };

  const onDrag = (e) => {
    if (draggedComponent) {
      const svgPoint = svgRef.current.createSVGPoint();
      svgPoint.x = e.clientX;
      svgPoint.y = e.clientY;
      const transformedPoint = svgPoint.matrixTransform(svgRef.current.getScreenCTM().inverse());

      setComponents(prev => ({
        ...prev,
        [draggedComponent]: {
          ...prev[draggedComponent],
          x: transformedPoint.x - dragOffset.x,
          y: transformedPoint.y - dragOffset.y
        }
      }));
    }
  };

  const endDrag = () => {
    setDraggedComponent(null);
  };

  useEffect(() => {
    document.addEventListener('mousemove', onDrag);
    document.addEventListener('mouseup', endDrag);
    return () => {
      document.removeEventListener('mousemove', onDrag);
      document.removeEventListener('mouseup', endDrag);
    };
  }, [draggedComponent]);
  

  return (
    <div className="pcb-container w-full h-[600px] relative mb-4">
      {showRealProduct ? (
        <div>

        Erhan

        </div>
      ) : (
        <svg ref={svgRef} width="100%" height="100%" viewBox="-100 -80 200 160" className="bg-gray-800">
          <g transform="rotate(180)">
            <g id="frontSide" style={{display: currentSide === 'front' ? 'block' : 'none'}}>
              {/* PCB Outline (Front) */}
              <path d="
                M46.92 0.69 A3 3 0 0 1 43.92 3.69
                L35.42 3.69 A3 3 0 0 0 32.42 6.69
                L32.42 30.69 A3 3 0 0 1 29.42 33.69
                L-31.57 33.69 A3 3 0 0 1 -34.57 30.69
                L-34.57 6.69 A3 3 0 0 0 -37.57 3.69
                L-46.07 3.69 A3 3 0 0 1 -49.07 0.69
                L-49.07 -33.30 A3 3 0 0 1 -46.07 -36.30
                L-39.07 -36.30 A3 3 0 0 0 -36.11 -38.80
                A3 3 0 0 1 -33.15 -41.30
                L-25.07 -41.30 A3 3 0 0 1 -22.11 -38.80
                A3 3 0 0 0 -19.15 -36.30
                L-10.36 -36.30 A3 3 0 0 1 -9.36 -37.30
                L-9.36 -40.30 A3 3 0 0 1 -8.36 -41.30
                L6.13 -41.30 A3 3 0 0 1 7.13 -40.30
                L7.13 -37.30 A3 3 0 0 1 8.13 -36.30
                L16.92 -36.30 A3 3 0 0 0 19.88 -38.80
                A3 3 0 0 1 22.84 -41.30
                L31.00 -41.30 A3 3 0 0 1 33.96 -38.80
                A3 3 0 0 0 36.92 -36.30
                L43.92 -36.30 A3 3 0 0 1 46.92 -33.30
                Z"
                fill="#000000" stroke="#FFFFFF" strokeWidth="0.1"/>
              {/* Orange Tab */}
              <rect x="29.57" y="-27.10" width="2.5" height="17" fill="#FFA500" />
              {/* Components (Front) */}
              <PixelArtLCD {...components.lcd} onMouseDown={(e) => startDrag(e, 'lcd')} />
              <PixelArtJoystick {...components.joystick} onMouseDown={(e) => startDrag(e, 'joystick')} />
              <rect 
                x={components.buttons.x} 
                y={components.buttons.y} 
                width={components.buttons.width} 
                height={components.buttons.height} 
                fill="#555555" 
                onMouseDown={(e) => startDrag(e, 'buttons')}
                style={{cursor: 'move'}}
              />
              <PixelArtUSBC {...components.typeC} onMouseDown={(e) => startDrag(e, 'typeC')} />
            </g>
            <g id="backSide" style={{display: currentSide === 'back' ? 'block' : 'none'}}>
              {/* PCB Outline (Back) */}
              <path d="
                M46.92 0.69 A3 3 0 0 1 43.92 3.69
                L35.42 3.69 A3 3 0 0 0 32.42 6.69
                L32.42 30.69 A3 3 0 0 1 29.42 33.69
                L-31.57 33.69 A3 3 0 0 1 -34.57 30.69
                L-34.57 6.69 A3 3 0 0 0 -37.57 3.69
                L-46.07 3.69 A3 3 0 0 1 -49.07 0.69
                L-49.07 -33.30 A3 3 0 0 1 -46.07 -36.30
                L-39.07 -36.30 A3 3 0 0 0 -36.11 -38.80
                A3 3 0 0 1 -33.15 -41.30
                L-25.07 -41.30 A3 3 0 0 1 -22.11 -38.80
                A3 3 0 0 0 -19.15 -36.30
                L-10.36 -36.30 A3 3 0 0 1 -9.36 -37.30
                L-9.36 -40.30 A3 3 0 0 1 -8.36 -41.30
                L6.13 -41.30 A3 3 0 0 1 7.13 -40.30
                L7.13 -37.30 A3 3 0 0 1 8.13 -36.30
                L16.92 -36.30 A3 3 0 0 0 19.88 -38.80
                A3 3 0 0 1 22.84 -41.30
                L31.00 -41.30 A3 3 0 0 1 33.96 -38.80
                A3 3 0 0 0 36.92 -36.30
                L43.92 -36.30 A3 3 0 0 1 46.92 -33.30
                Z"
                fill="#000000" stroke="#FFFFFF" strokeWidth="0.1"/>
              {/* Components (Back) */}
              <PixelArtUSBC {...components.typeC} onMouseDown={(e) => startDrag(e, 'typeC')} />
              <rect 
                x={components.sdCard.x} 
                y={components.sdCard.y} 
                width={components.sdCard.width} 
                height={components.sdCard.height} 
                fill="#888888" 
                onMouseDown={(e) => startDrag(e, 'sdCard')}
                style={{cursor: 'move'}}
              />
              <rect 
                x={components.header.x} 
                y={components.header.y} 
                width={components.header.width} 
                height={components.header.height} 
                fill="#888888" 
                onMouseDown={(e) => startDrag(e, 'header')}
                style={{cursor: 'move'}}
              />
            </g>
          </g>
        </svg>
      )}
      <button 
        onClick={toggleSide}
        className="absolute bottom-2 right-2 bg-[#FCEB55] text-[#293C43] px-4 py-2 rounded hover:bg-[#E6D44E] transition font-semibold"
      >
        Flip PCB
      </button>

    </div>
  );
};

export default PCBViewer;